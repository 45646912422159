<template>
  <el-form ref="form" :model="form" label-width="80px" :disabled="disabled"
           :class="{'detail-disabled': disabled}">
    <el-form-item label="申请人" prop="remark">
      <el-input v-model="form.remark"/>
    </el-form-item>
    <el-form-item label="申请理由" prop="applyReason">
      <el-input v-model="form.applyReason" type="textarea" placeholder="请输入申请理由" />
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  name: 'demoFrom',
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      // 表单参数
      form: {},
      disabled: true,
    };
  },
  created() {
    this.getFormData();
  },
  methods: {
    /** 查询表单详情 */
    getFormData() {
      // getMerchant(this.id).then(response => {
      //   this.form = response.data;
      // });
    }
  }
}
</script>

<style scoped lang="scss">
.el-date-editor, .el-select {
  width: 100%;
}
</style>
