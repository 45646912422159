var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo, sidebarList: true } },
    [
      _c(
        "el-scrollbar",
        {
          class: _vm.settings.sideTheme,
          attrs: {
            "wrap-style": _vm.sidebarBackground,
            "wrap-class": "scrollbar-wrapper",
          },
        },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": "rgba(255,255,255,0)",
                "unique-opened": true,
                "active-text-color": _vm.settings.theme,
                "collapse-transition": false,
                mode: "vertical",
                "text-color": "#8F96A2",
                "active-text-color": "#3e3f42",
              },
            },
            _vm._l(_vm.sidebarRouters, function (route, index) {
              return _c("sidebar-item", {
                key: route.path + index,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }