var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      class: { "detail-disabled": _vm.disabled },
      attrs: { model: _vm.form, "label-width": "80px", disabled: _vm.disabled },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "申请人", prop: "remark" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.remark,
              callback: function ($$v) {
                _vm.$set(_vm.form, "remark", $$v)
              },
              expression: "form.remark",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "申请理由", prop: "applyReason" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", placeholder: "请输入申请理由" },
            model: {
              value: _vm.form.applyReason,
              callback: function ($$v) {
                _vm.$set(_vm.form, "applyReason", $$v)
              },
              expression: "form.applyReason",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }