var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_c("span", [_vm._v("发起流程")])]
          ),
          _c("el-col", { attrs: { span: 18, offset: 3 } }, [
            _vm.formOpen
              ? _c(
                  "div",
                  { staticClass: "form-conf" },
                  [
                    _c("parser", {
                      key: new Date().getTime(),
                      ref: "parser",
                      attrs: { "form-conf": _vm.formData },
                      on: { submit: _vm.submit, getData: _vm.getData },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }