<template>
  <el-row style="margin-bottom: 24px">
    <el-col :span="24">
      <el-card>
        <div slot="header" class="card-title">
          工单类型分布
        </div>
        <div style="margin:0 auto;width:100%;height:280px">
          <div id="type-right" style="width:100%;height:100%"></div>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios'
import echarts from 'echarts'
import {selectWorkOrderTypeStatistics} from "@/api/biz/workOrder";

export default {
  name: 'WorkTypeStatistics',
  components: {},
  watch: {
    dateRightType() {
      this.dateRight = this.updateDate(this.dateRightType)
    },
    dateRight() {
      this.getVisitorStatistics()
    }
  },
  data() {
    return {
      dateRightType: 'sevenDay',
      dateRightTypeArr: [
        { name: '近7天', value: 'sevenDay' },
        { name: '近30天', value: 'thirtyDay' }
      ],
      dateRight: [],
      chartRight: null,
      dataRight: []
    }
  },
  mounted() {
    //this.dateRight = this.updateDate(this.dateRightType);
    //this.chartRight = this.initChart(this.chartRight,this.dataRight,"chartRight")
    this.getVisitorStatistics()
  },
  methods: {
    getVisitorStatistics() {
      let requests = []
      requests.push(this.getDicts('work_order_type'))
      requests.push(selectWorkOrderTypeStatistics())
      axios.all(requests).then(axios.spread(function() {
        let data = []
        for (let i = 0; i < arguments.length; i++) {
          let res = arguments[i]
          if (i == 0) {
            res.data.forEach(item => {
              data.push({ type: item.dictLabel, dictValue: item.dictValue, value: 0 })
            })
          } else {
            res.data.forEach(item => {
              for (let j = 0; j < data.length; j++) {
                if (data[j].dictValue == item.axisX) {
                  data[j].value = item.axisY
                }
              }
            })
          }
        }

        // const piePlot = new Pie('container-right', {
        //   appendPadding: 10,
        //   data,
        //   angleField: 'value',
        //   colorField: 'type',
        //   radius: 0.75,
        //   color: ['#3281FF', '#00B1FF', '#3AFFF4', '#1F62A3', '#FFB600', '#FF6600', '#F54040', '#7B5FEB', '#FF93C4', '#C0DAF6'],
        //   label: {
        //     type: 'spider',
        //     labelHeight: 28,
        //     content: '{name}\n{percentage}'
        //   },
        //   legend: {
        //     position: 'top'
        //   },
        //   interactions: [{ type: 'element-selected' }, { type: 'element-active' }]
        // })
        //
        // piePlot.render()

        console.log(data)

        const count = data.map(i => i.value).reduce((a, b) => {
          return parseInt(a) + parseInt(b);
        }, 1);

        const chartDom = document.getElementById('type-right')
        const myChart = echarts.init(chartDom)
        let option = null

        option = {
          color: [
            '#0061FC',
            '#18A383',
            '#6873E6',
            '#FC9C00',
            '#FF492E',
            '#DF4399',
            '#741FE0',
            '#0048BA',
            '#D3DC09',
            '#485261',
            '#00FFD0',
            '#0097FF',
            '#8BFFF1',
            '#7E50FA',
            '#F05779',
            '#FC7B4E',
            '#FCBC4E',
            '#FFFB7A',
            '#85FF7A',
            '#96DFFF'
          ],
          tooltip: {
            trigger: 'item',
            backgroundColor: 'rgba(255,255,255,0.95)',
            extraCssText: 'box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);',
            textStyle: {
              fontSize: 14,
              color: '#303133'
            },
            formatter(params){
              return params.marker  + params.data.name  + ':'+params.data.value+'个（' + (((params.data.value / count) * 100).toFixed(2)) + '%）'
            }
          },
          legend: {
            orient: 'vertical',
            type: 'scroll',
            right: 10,
            top: 20,
            bottom: 20,
            y: 'center',
            itemWidth: 8,
            itemHeight: 8,
            icon: 'circle',
            textStyle: {
              fontSize: 14,
              color: '#303133',
              rich: {
                a:{ //formatter内return 内容中对应名字的样式
                  fontSize: 14,
                  padding: [0, 0, 0, 10],
                  width: 50,
                },
                b:{
                  fontSize: 14,
                  padding: [0, 0, 0, 10],
                  width: 35,
                }
              },
            },
            formatter(params){
              let d = data.filter(function(item) {
                return item.type == params;
              })[0];
              return `{a|${params.slice(0,6)}} {b|${d.value}个} ${((d.value / count) * 100).toFixed(2)}%`
            }
          },
          series: [
            {
              type: 'pie',
              center: ['35%', '50%'],
              radius: ['40%', '70%'],
              itemStyle: {
                borderColor: '#ffffff',
                borderWidth: 1
              },
              label: {
                show: false,
                formatter:'{b}：{c}个({d}%)'
              },
              data: data.map((item) => {
                return {
                  name: item.type,
                  value: item.value
                }
              })
            }
          ]
        }

        option && myChart.setOption(option)
      }))
    }
  }
}
</script>
