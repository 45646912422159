var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showWorkOrderDetail
    ? _c("work-order-detail", {
        attrs: {
          workOrderNo: _vm.workOrderNo,
          show: _vm.showWorkOrderDetail,
          detailType: _vm.detailType,
          fromSource: _vm.fromSource,
          uuid: _vm.uuid,
          fullscreen: true,
          "show-close": false,
          modal: false,
        },
        on: {
          "update:show": function ($event) {
            _vm.showWorkOrderDetail = $event
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }