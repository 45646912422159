var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm._show,
            "close-on-click-modal": false,
            width: _vm.dialogWidth,
            fullscreen: _vm.fullscreen,
            "show-close": _vm.showClose,
            modal: _vm.modal,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm._show = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 17 } },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { size: "medium", title: "任务信息", column: 2 } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "工单编号" } },
                        [_vm._v(_vm._s(_vm.workOrderDetail.workOrderNo))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "任务名称" } },
                        [
                          _vm.isUpdateStatus
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入任务名称" },
                                model: {
                                  value: _vm.workOrderDetail.workOrderName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workOrderDetail,
                                      "workOrderName",
                                      $$v
                                    )
                                  },
                                  expression: "workOrderDetail.workOrderName",
                                },
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm.workOrderDetail.workOrderName)
                                ),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "任务级别" } },
                        [
                          _vm.isUpdateStatus
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: { width: "240px" },
                                  attrs: {
                                    placeholder: "任务级别",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.workOrderDetail.workOrderLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workOrderDetail,
                                        "workOrderLevel",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "workOrderDetail.workOrderLevel",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.work_order_level,
                                  function (dict) {
                                    return _c("el-option", {
                                      key: dict.value,
                                      attrs: {
                                        label: dict.label,
                                        value: dict.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _c("dict-tag", {
                                attrs: {
                                  options: _vm.dict.type.work_order_level,
                                  value: _vm.workOrderDetail.workOrderLevel,
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "任务类型" } },
                        [
                          _vm.isUpdateStatus
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: { width: "240px" },
                                  attrs: {
                                    placeholder: "任务类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.workOrderDetail.workOrderType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.workOrderDetail,
                                        "workOrderType",
                                        $$v
                                      )
                                    },
                                    expression: "workOrderDetail.workOrderType",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.work_order_type,
                                  function (dict) {
                                    return _c("el-option", {
                                      key: dict.value,
                                      attrs: {
                                        label: dict.label,
                                        value: dict.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _c("dict-tag", {
                                attrs: {
                                  options: _vm.dict.type.work_order_type,
                                  value: _vm.workOrderDetail.workOrderType,
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "任务状态" } },
                        [
                          _c("dict-tag", {
                            attrs: {
                              options: _vm.dict.type.work_order_status,
                              value: _vm.workOrderDetail.workOrderStatus,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "任务描述" } },
                        [
                          _vm.isUpdateStatus
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: "请输入任务描述",
                                  type: "textarea",
                                },
                                model: {
                                  value: _vm.workOrderDetail.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workOrderDetail,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "workOrderDetail.description",
                                },
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.workOrderDetail.description)),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发生地点" } },
                        [
                          _vm.isUpdateStatus
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入发生地点" },
                                model: {
                                  value: _vm.workOrderDetail.address,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workOrderDetail,
                                      "address",
                                      $$v
                                    )
                                  },
                                  expression: "workOrderDetail.address",
                                },
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.workOrderDetail.address)),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发生时间" } },
                        [
                          _vm.isUpdateStatus
                            ? _c("el-date-picker", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  clearable: "",
                                  size: "small",
                                  type: "date",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "选择发生时间",
                                },
                                model: {
                                  value: _vm.workOrderDetail.workOrderTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workOrderDetail,
                                      "workOrderTime",
                                      $$v
                                    )
                                  },
                                  expression: "workOrderDetail.workOrderTime",
                                },
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseTime(
                                      _vm.workOrderDetail.workOrderTime,
                                      "{y}-{m}-{d} {h}:{i}:{s}"
                                    )
                                  )
                                ),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发起人" } },
                        [
                          _vm._v(
                            _vm._s(_vm.workOrderDetail.launchPersonName) +
                              "(" +
                              _vm._s(_vm.workOrderDetail.launchPersonPhone) +
                              ")"
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "附件" } },
                        [
                          (_vm.workOrderDetail.fileList &&
                            _vm.workOrderDetail.fileList.length > 0) ||
                          _vm.isUpdateStatus
                            ? _c("file-upload", {
                                staticClass: "file-show-container",
                                attrs: {
                                  disabled: !_vm.isUpdateStatus,
                                  fileType: ["png", "jpg", "jpeg", "mp4"],
                                  fileSize: 100,
                                  valueType: "object",
                                },
                                model: {
                                  value: _vm.workOrderDetail.fileList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workOrderDetail,
                                      "fileList",
                                      $$v
                                    )
                                  },
                                  expression: "workOrderDetail.fileList",
                                },
                              })
                            : _c("span", [_vm._v("无")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 5 } }, [
                _c(
                  "div",
                  { staticStyle: { "padding-top": "20px" } },
                  [
                    _c("qq-map", {
                      ref: "map",
                      attrs: { id: "deviceAlarmContainer", height: "200px" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-descriptions", { attrs: { title: "处理信息" } }),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.workOrderProcessList } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "处理环节",
                          align: "center",
                          prop: "processNode",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("dict-tag", {
                                  attrs: {
                                    options:
                                      _vm.dict.type.work_order_process_node,
                                    value: scope.row.processNode,
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "处理时间",
                          align: "center",
                          prop: "createTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "处理单位",
                          align: "center",
                          prop: "processDeptName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "处理人",
                          align: "center",
                          prop: "processPersonName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "处理意见",
                          align: "center",
                          prop: "description",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "附件",
                          align: "center",
                          prop: "fileList",
                          "min-width": "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.fileList &&
                                scope.row.fileList.length > 0
                                  ? _c(
                                      "div",
                                      { staticStyle: { margin: "10px 0" } },
                                      [
                                        _c("file-upload", {
                                          staticClass: "file-show-container",
                                          attrs: { disabled: true },
                                          model: {
                                            value: scope.row.fileList,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "fileList",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.fileList",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("span", [_vm._v("无")]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.workOrderDetail.workOrderStatus == "0" &&
              _vm.detailType == "assign"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-descriptions", {
                        staticStyle: { "margin-top": "50px" },
                        attrs: { title: "工单分派" },
                      }),
                      _c(
                        "el-form",
                        {
                          ref: "fromAssign",
                          attrs: {
                            model: _vm.fromAssign,
                            "label-width": "80px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "分派单位" } },
                            [
                              _vm._l(_vm.assignList, function (assign) {
                                return _c("div", [
                                  _vm._v(
                                    _vm._s(assign.processDeptName) +
                                      " - " +
                                      _vm._s(assign.processPersonName)
                                  ),
                                ])
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.onSelectUsers },
                                },
                                [_vm._v("分派")]
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "分派意见", prop: "description" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 6,
                                  placeholder: "请输入分派意见",
                                },
                                model: {
                                  value: _vm.fromAssign.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fromAssign, "description", $$v)
                                  },
                                  expression: "fromAssign.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-button", { on: { click: _vm.cancel } }, [
                            _vm._v("取 消"),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submitFormAssign },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.workOrderDetail.workOrderStatus == "1" &&
              _vm.detailType == "transfer"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-descriptions", {
                        staticStyle: { "margin-top": "50px" },
                        attrs: { title: "工单转派" },
                      }),
                      _c(
                        "el-form",
                        {
                          ref: "fromAssign",
                          attrs: {
                            model: _vm.fromAssign,
                            "label-width": "80px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "转派单位" } },
                            [
                              _vm._l(_vm.assignList, function (assign) {
                                return _c("div", [
                                  _vm._v(
                                    _vm._s(assign.processDeptName) +
                                      " - " +
                                      _vm._s(assign.processPersonName)
                                  ),
                                ])
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.onSelectUsers },
                                },
                                [_vm._v("转派")]
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "转派意见", prop: "description" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 6,
                                  placeholder: "请输入转派意见",
                                },
                                model: {
                                  value: _vm.fromAssign.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fromAssign, "description", $$v)
                                  },
                                  expression: "fromAssign.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-button", { on: { click: _vm.cancel } }, [
                            _vm._v("取 消"),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submitFormTransfer },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.workOrderDetail.workOrderStatus == "2" &&
              _vm.detailType == "backApproval"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-descriptions", { attrs: { title: "退回审核" } }),
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            model: _vm.fromBackApproval,
                            "label-width": "80px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "审核结果",
                                prop: "approvalResult",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.fromBackApproval.approvalResult,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.fromBackApproval,
                                        "approvalResult",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "fromBackApproval.approvalResult",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("转办"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("打回"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "3" } }, [
                                    _vm._v("归档"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.fromBackApproval.approvalResult == "1",
                                  expression:
                                    "fromBackApproval.approvalResult == '1'",
                                },
                              ],
                              attrs: {
                                label: "转派单位",
                                prop: "workOrderName",
                              },
                            },
                            [
                              _vm._l(_vm.assignList, function (assign) {
                                return _c("div", [
                                  _vm._v(
                                    _vm._s(assign.processDeptName) +
                                      " - " +
                                      _vm._s(assign.processPersonName)
                                  ),
                                ])
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.onSelectUsers },
                                },
                                [_vm._v("分派")]
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "审核意见", prop: "description" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 6,
                                  placeholder: "请输入审核意见",
                                },
                                model: {
                                  value: _vm.fromBackApproval.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fromBackApproval,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "fromBackApproval.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-button", { on: { click: _vm.cancel } }, [
                            _vm._v("取 消"),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submitFormBackApproval },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.workOrderDetail.workOrderStatus == "4" &&
              _vm.detailType == "approval"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-descriptions", {
                        attrs: { title: "处置结果复核" },
                      }),
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            model: _vm.fromApproval,
                            "label-width": "80px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "复核结果",
                                prop: "approvalResult",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.fromApproval.approvalResult,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.fromApproval,
                                        "approvalResult",
                                        $$v
                                      )
                                    },
                                    expression: "fromApproval.approvalResult",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("通过"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("不通过"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "复核意见", prop: "description" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 6,
                                  placeholder: "请输入复核意见",
                                },
                                model: {
                                  value: _vm.fromApproval.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fromApproval,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "fromApproval.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "附件上传", prop: "fileList" } },
                            [
                              _c("file-upload", {
                                attrs: {
                                  valueType: "object",
                                  fileType: ["png", "jpg", "jpeg", "mp4"],
                                  fileSize: 100,
                                },
                                model: {
                                  value: _vm.fromApproval.fileList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fromApproval, "fileList", $$v)
                                  },
                                  expression: "fromApproval.fileList",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-button", { on: { click: _vm.cancel } }, [
                            _vm._v("取 消"),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submitFormApproval },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.workOrderDetail.workOrderStatus == "1" &&
              _vm.detailType == "workOrderAccept"
                ? _c("el-col", { attrs: { span: 24 } })
                : _vm._e(),
            ],
            1
          ),
          _vm.isUpdateStatus
            ? _c(
                "div",
                { staticStyle: { float: "right", "margin-top": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.comfirmUpdate },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "候选用户", visible: _vm.userOpen, width: "60%" },
          on: {
            "update:visible": function ($event) {
              _vm.userOpen = $event
            },
          },
        },
        [
          _vm.userOpen
            ? _c(
                "el-row",
                { attrs: { type: "flex", gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-card",
                        {
                          staticStyle: { height: "600px", overflow: "auto" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [_c("span", [_vm._v("部门列表")])]
                          ),
                          _c(
                            "div",
                            { staticClass: "head-container" },
                            [
                              _c("el-input", {
                                staticStyle: { "margin-bottom": "20px" },
                                attrs: {
                                  placeholder: "请输入部门名称",
                                  clearable: "",
                                  size: "small",
                                  "prefix-icon": "el-icon-search",
                                },
                                model: {
                                  value: _vm.deptName,
                                  callback: function ($$v) {
                                    _vm.deptName = $$v
                                  },
                                  expression: "deptName",
                                },
                              }),
                              _c("el-tree", {
                                ref: "tree",
                                attrs: {
                                  data: _vm.deptOptions,
                                  props: _vm.deptProps,
                                  "expand-on-click-node": false,
                                  "filter-node-method": _vm.filterNode,
                                  "default-expand-all": "",
                                },
                                on: { "node-click": _vm.handleNodeClick },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 17 } },
                    [
                      _c(
                        "div",
                        { staticClass: "search-area" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "queryForm",
                              attrs: {
                                model: _vm.queryParams,
                                size: "small",
                                inline: true,
                                "label-width": "68px",
                              },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "用户账号",
                                    prop: "userName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      placeholder: "请输入用户账号",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.userName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "userName",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.userName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "用户名称",
                                    prop: "personName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      placeholder: "请输入用户名称",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.personName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "personName",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.personName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-search",
                                        size: "mini",
                                      },
                                      on: { click: _vm.handleQuery },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-refresh",
                                        size: "mini",
                                      },
                                      on: { click: _vm.resetQuery },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          ref: "multipleTable",
                          attrs: {
                            height: "500",
                            data: _vm.userTableList,
                            border: "",
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              width: "50",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "用户账号",
                              align: "center",
                              prop: "userName",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "用户名称",
                              align: "center",
                              prop: "personName",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "部门",
                              align: "center",
                              prop: "deptName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("pagination", {
                        attrs: {
                          total: _vm.userTotal,
                          page: _vm.queryParams.pageNum,
                          limit: _vm.queryParams.pageSize,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.queryParams, "pageNum", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.queryParams, "pageSize", $event)
                          },
                          pagination: _vm.getUserList,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleTaskUserComplete },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userOpen = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }