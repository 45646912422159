var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", [
        _c(
          "div",
          {
            staticClass: "card-title",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _vm._v(" 工单完成情况 "),
            _c(
              "div",
              { staticStyle: { flex: "1", "text-align": "right" } },
              [
                _c("el-date-picker", {
                  staticStyle: { "margin-left": "15px" },
                  attrs: {
                    type: "monthrange",
                    align: "right",
                    "unlink-panels": "",
                    "value-format": "yyyy-MM",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                  },
                  model: {
                    value: _vm.dateRight,
                    callback: function ($$v) {
                      _vm.dateRight = $$v
                    },
                    expression: "dateRight",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticStyle: { margin: "0 auto", width: "100%", height: "280px" } },
          [
            _c("div", {
              staticStyle: { width: "100%", height: "100%" },
              attrs: { id: "work-order-completion-statistics" },
            }),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }