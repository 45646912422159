var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "statistics-container", attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { staticClass: "statistics-right", attrs: { span: 24 } },
            [
              _c("div", { staticClass: "statistics" }, [
                _c("div", { staticClass: "statistics-item bg1" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("待分派")]),
                  _c("div", { staticClass: "statistics-num" }, [
                    _vm._v(" " + _vm._s(_vm.statisticDate.toBeAssignNum || 0)),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "5px",
                          "font-size": "16px",
                          color: "#919399",
                        },
                      },
                      [_vm._v("单")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "statistics-item bg2" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("待接单")]),
                  _c("div", { staticClass: "statistics-num" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          parseInt(_vm.statisticDate.pendingOrdersNum) || 0
                        )
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "5px",
                          "font-size": "16px",
                          color: "#919399",
                        },
                      },
                      [_vm._v("单")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "statistics-item bg3" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("办理中")]),
                  _c("div", { staticClass: "statistics-num" }, [
                    _vm._v(
                      " " +
                        _vm._s(parseInt(_vm.statisticDate.processingNum) || 0)
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "5px",
                          "font-size": "16px",
                          color: "#919399",
                        },
                      },
                      [_vm._v("单")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "statistics-item bg3" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("今日新增")]),
                  _c("div", { staticClass: "statistics-num" }, [
                    _vm._v(
                      " " + _vm._s(parseInt(_vm.statisticDate.todayNewNum) || 0)
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "5px",
                          "font-size": "16px",
                          color: "#919399",
                        },
                      },
                      [_vm._v("单")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "statistics-item bg3" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("累计工单")]),
                  _c("div", { staticClass: "statistics-num" }, [
                    _vm._v(
                      " " +
                        _vm._s(parseInt(_vm.statisticDate.workOrderSum) || 0)
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "5px",
                          "font-size": "16px",
                          color: "#919399",
                        },
                      },
                      [_vm._v("单")]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "el-col",
            { staticClass: "statistics-left", attrs: { span: 12 } },
            [_c("work-type-statistics")],
            1
          ),
          _c(
            "el-col",
            { staticClass: "statistics-left", attrs: { span: 12 } },
            [_c("work-level-statistics")],
            1
          ),
          _c(
            "el-col",
            { staticClass: "statistics-left", attrs: { span: 24 } },
            [_c("work-order-completion-statistics")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }