var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticStyle: { "margin-bottom": "24px" } },
    [
      _c(
        "el-col",
        { attrs: { span: 24 } },
        [
          _c("el-card", [
            _c(
              "div",
              {
                staticClass: "card-title",
                attrs: { slot: "header" },
                slot: "header",
              },
              [_vm._v(" 工单级别分布 ")]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  margin: "0 auto",
                  width: "100%",
                  height: "280px",
                },
              },
              [
                _c("div", {
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { id: "level-right" },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }