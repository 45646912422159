<template>
<div>
  <demo-from v-if="businessType == 'demo'" :id="businessId"></demo-from>
</div>
</template>

<script>
import DemoFrom from './form/demoFrom'
export default {
  name: 'businessForm',
  components: { DemoFrom },
  props: {
    businessId: {
      required: true
    },
    businessType: {
      required: true
    }
  }
}
</script>
