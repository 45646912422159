var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "padding-top": "0px" } },
    [
      _c(
        "el-button",
        {
          attrs: { plain: "", icon: "el-icon-arrow-left", size: "mini" },
          on: { click: _vm.handlePageBack },
        },
        [_vm._v("返回")]
      ),
      _c(
        "el-tabs",
        { attrs: { "tab-position": "top", value: "form" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "表单信息", name: "form" } },
            [
              _c("business-form", {
                attrs: {
                  businessId: _vm.processVariables.id,
                  businessType: _vm.processVariables.category,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: {
            "tab-position": "top",
            value: _vm.readOnly ? "record" : "approval",
          },
        },
        [
          !_vm.readOnly
            ? _c(
                "el-tab-pane",
                { attrs: { label: "任务办理", name: "approval" } },
                [
                  _vm.taskFormOpen
                    ? _c(
                        "el-card",
                        { staticClass: "box-card", attrs: { shadow: "hover" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [_c("span", [_vm._v("填写表单")])]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 20, offset: 2 } },
                            [
                              _c("parser", {
                                ref: "taskFormParser",
                                attrs: { "form-conf": _vm.taskFormData },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-card",
                    { staticClass: "box-card", attrs: { shadow: "hover" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("审批流程")])]
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 20, offset: 2 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "taskForm",
                                  attrs: {
                                    model: _vm.taskForm,
                                    rules: _vm.rules,
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "审批意见",
                                        prop: "comment",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          rows: 5,
                                          placeholder: "请输入 审批意见",
                                        },
                                        model: {
                                          value: _vm.taskForm.comment,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.taskForm,
                                              "comment",
                                              $$v
                                            )
                                          },
                                          expression: "taskForm.comment",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          attrs: {
                            gutter: 10,
                            type: "flex",
                            justify: "center",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 1.5 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-circle-check",
                                    type: "success",
                                  },
                                  on: { click: _vm.handleComplete },
                                },
                                [_vm._v("通过")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 1.5 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-chat-line-square",
                                    type: "primary",
                                  },
                                  on: { click: _vm.handleDelegate },
                                },
                                [_vm._v("委派")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 1.5 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-thumb",
                                    type: "success",
                                  },
                                  on: { click: _vm.handleTransfer },
                                },
                                [_vm._v("转办")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 1.5 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-refresh-left",
                                    type: "warning",
                                  },
                                  on: { click: _vm.handleReturn },
                                },
                                [_vm._v("退回")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 1.5 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-circle-close",
                                    type: "danger",
                                  },
                                  on: { click: _vm.handleReject },
                                },
                                [_vm._v("拒绝")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: "流转记录", name: "record" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "never" } },
                [
                  _c("el-col", { attrs: { span: 20, offset: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c(
                          "el-timeline",
                          _vm._l(
                            _vm.historyProcNodeList,
                            function (item, index) {
                              return _c(
                                "el-timeline-item",
                                {
                                  key: index,
                                  attrs: {
                                    icon: _vm.setIcon(item.endTime),
                                    color: _vm.setColor(item.endTime),
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    { staticStyle: { "font-weight": "700" } },
                                    [_vm._v(_vm._s(item.activityName))]
                                  ),
                                  item.activityType === "startEvent"
                                    ? _c(
                                        "el-card",
                                        {
                                          staticClass: "box-card",
                                          attrs: { shadow: "hover" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.assigneeName) +
                                              " 在 " +
                                              _vm._s(item.createTime) +
                                              " 发起流程 "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.activityType === "userTask"
                                    ? _c(
                                        "el-card",
                                        {
                                          staticClass: "box-card",
                                          attrs: { shadow: "hover" },
                                        },
                                        [
                                          _c(
                                            "el-descriptions",
                                            {
                                              attrs: {
                                                column: 5,
                                                labelStyle: {
                                                  "font-weight": "bold",
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-descriptions-item",
                                                {
                                                  attrs: { label: "实际办理" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.assigneeName || "-"
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-descriptions-item",
                                                {
                                                  attrs: { label: "候选办理" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.candidate || "-"
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-descriptions-item",
                                                {
                                                  attrs: { label: "接收时间" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.createTime || "-"
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-descriptions-item",
                                                {
                                                  attrs: { label: "办结时间" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.endTime || "-")
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-descriptions-item",
                                                { attrs: { label: "耗时" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.duration || "-")
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          item.commentList &&
                                          item.commentList.length > 0
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  item.commentList,
                                                  function (comment, index) {
                                                    return _c(
                                                      "div",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "el-divider",
                                                          {
                                                            attrs: {
                                                              "content-position":
                                                                "left",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-tag",
                                                              {
                                                                attrs: {
                                                                  type: _vm.approveTypeTag(
                                                                    comment.type
                                                                  ),
                                                                  size: "mini",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.commentType(
                                                                      comment.type
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "el-tag",
                                                              {
                                                                attrs: {
                                                                  type: "info",
                                                                  effect:
                                                                    "plain",
                                                                  size: "mini",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    comment.time
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              comment.fullMessage
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.activityType === "endEvent"
                                    ? _c(
                                        "el-card",
                                        {
                                          staticClass: "box-card",
                                          attrs: { shadow: "hover" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.createTime) +
                                              " 结束流程 "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "流程跟踪", name: "track" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "never" } },
                [
                  _c("process-viewer", {
                    style: "height:" + _vm.height,
                    attrs: {
                      xml: _vm.xmlData,
                      finishedInfo: _vm.finishedInfo,
                      allCommentList: _vm.historyProcNodeList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.returnTitle,
            visible: _vm.returnOpen,
            width: "40%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.returnOpen = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "taskForm",
              attrs: { model: _vm.taskForm, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "退回节点", prop: "targetKey" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.taskForm.targetKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.taskForm, "targetKey", $$v)
                        },
                        expression: "taskForm.targetKey",
                      },
                    },
                    _vm._l(_vm.returnTaskList, function (item) {
                      return _c(
                        "el-radio-button",
                        { key: item.id, attrs: { label: item.id } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.returnOpen = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitReturn } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.userData.title,
            visible: _vm.userData.open,
            width: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.userData, "open", $event)
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-card",
                    {
                      staticStyle: { height: "600px", overflow: "auto" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("span", [_vm._v("部门列表")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "head-container" },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入部门名称",
                              clearable: "",
                              size: "small",
                              "prefix-icon": "el-icon-search",
                            },
                            model: {
                              value: _vm.deptName,
                              callback: function ($$v) {
                                _vm.deptName = $$v
                              },
                              expression: "deptName",
                            },
                          }),
                          _c("el-tree", {
                            ref: "tree",
                            attrs: {
                              data: _vm.deptOptions,
                              props: _vm.deptProps,
                              "expand-on-click-node": false,
                              "filter-node-method": _vm.filterNode,
                              "default-expand-all": "",
                            },
                            on: { "node-click": _vm.handleNodeClick },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.userLoading,
                          expression: "userLoading",
                        },
                      ],
                      key: _vm.userData.type,
                      ref: "userTable",
                      attrs: {
                        height: "500",
                        data: _vm.userList,
                        "highlight-current-row": "",
                      },
                      on: {
                        "current-change": _vm.changeCurrentUser,
                        "selection-change": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _vm.userData.type === "copy" ||
                      _vm.userData.type === "next"
                        ? _c("el-table-column", {
                            attrs: { width: "55", type: "selection" },
                          })
                        : _c("el-table-column", {
                            attrs: { width: "30" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: scope.row.userId },
                                        model: {
                                          value: _vm.currentUserId,
                                          callback: function ($$v) {
                                            _vm.currentUserId = $$v
                                          },
                                          expression: "currentUserId",
                                        },
                                      },
                                      [_vm._v(_vm._s(""))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                      _c("el-table-column", {
                        attrs: {
                          label: "用户名",
                          align: "center",
                          prop: "personName",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.userName +
                                        "(" +
                                        scope.row.name +
                                        ")"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "部门",
                          align: "center",
                          prop: "deptName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("pagination", {
                    attrs: {
                      total: _vm.total,
                      page: _vm.queryParams.pageNum,
                      limit: _vm.queryParams.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.queryParams, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.queryParams, "pageSize", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userData.open = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitUserData },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }