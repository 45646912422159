var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-wrapper",
      class: _vm.classObj,
      style: { "--current-color": _vm.theme },
    },
    [
      _c("navbar"),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: {
            hasTagsView: _vm.needTagsView,
            sidebarHide: _vm.sidebar.hide,
          },
        },
        [
          !_vm.sidebar.hide
            ? _c("sidebar", { staticClass: "sidebar-container" })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "right-main" },
            [
              _vm.needTagsView ? _c("tags-view") : _vm._e(),
              !_vm.topNav
                ? _c("breadcrumb", {
                    staticClass: "breadcrumb-container",
                    attrs: { id: "breadcrumb-container" },
                  })
                : _vm._e(),
              _vm.topNav
                ? _c("top-nav", {
                    staticClass: "topmenu-container",
                    attrs: { id: "topmenu-container" },
                  })
                : _vm._e(),
              _c("app-main"),
            ],
            1
          ),
          _c("right-panel", [_c("settings")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }