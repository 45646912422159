import Cookies from 'js-cookie'

function getPath() {
  return __webpack_public_path__;
}
function getCacheKeyPrefix(key){
  return getPath() +":" + key;
}

const cookiesCache = {
  set (key, value, attributes) {
    if (key != null && value != null) {
      attributes = attributes ? attributes : {}
      Cookies.set(key, value, { ...attributes, path: getPath() })
    }
  },
  get (key) {
    if (key == null) {
      return null
    }
    return Cookies.get(key)
  },
  setJSON (key, jsonValue) {
    if (jsonValue != null) {
      this.set(key, JSON.stringify(jsonValue))
    }
  },
  getJSON (key) {
    const value = this.get(key)
    if (value != null) {
      return JSON.parse(value)
    }
    return null;
  },
  remove (key) {
    Cookies.remove(key, { path: getPath() });
  }
}

const sessionCache = {
  set (key, value) {
    if (!sessionStorage) {
      return
    }
    if (key != null && value != null) {
      sessionStorage.setItem(getCacheKeyPrefix(key), value)
    }
  },
  get (key) {
    if (!sessionStorage) {
      return null
    }
    if (key == null) {
      return null
    }
    return sessionStorage.getItem(getCacheKeyPrefix(key))
  },
  setJSON (key, jsonValue) {
    if (jsonValue != null) {
      this.set(key, JSON.stringify(jsonValue))
    }
  },
  getJSON (key) {
    const value = this.get(key)
    if (value != null) {
      return JSON.parse(value)
    }
  },
  remove (key) {
    sessionStorage.removeItem(getCacheKeyPrefix(key));
  }
}
const localCache = {
  set (key, value) {
    if (!localStorage) {
      return
    }
    if (key != null && value != null) {
      localStorage.setItem(getCacheKeyPrefix(key), value)
    }
  },
  get (key) {
    if (!localStorage) {
      return null
    }
    if (key == null) {
      return null
    }
    return localStorage.getItem(getCacheKeyPrefix(key))
  },
  setJSON (key, jsonValue) {
    if (jsonValue != null) {
      this.set(key, JSON.stringify(jsonValue))
    }
  },
  getJSON (key) {
    const value = this.get(key)
    if (value != null) {
      return JSON.parse(value)
    }
    return null;
  },
  remove (key) {
    localStorage.removeItem(getCacheKeyPrefix(key));
  }
}

export default {
  /**
   * 会话级缓存
   */
  session: sessionCache,
  /**
   * 本地缓存
   */
  local: localCache,
  /**
   * Cookie缓存
   */
  cookies: cookiesCache
}
