<template>
  <div class="sidebar-logo-container">
    <transition name="sidebarLogoFade">
      <router-link class="sidebar-logo-link" to="/">
<!--        <img v-if="logo" :src="logo" class="sidebar-logo" />-->
        <h1 class="sidebar-title"><span v-if="scenicName">{{ scenicName }} | </span><span>{{ title }}</span></h1>
      </router-link>
    </transition>
  </div>
</template>
<script>
import variables from '@/assets/styles/variables.scss'
export default {
  name: 'SidebarLogo',
  computed: {
    variables() {
      return variables;
    },
    sideTheme() {
      return this.$store.state.settings.sideTheme
    }
  },
  created() {
    this.getConfigKey("sys.pc.base.config").then(response => {
      let data = JSON.parse(response.data);
      this.title = data.webSiteName || this.title;
      this.scenicName = data.scenicName;
      this.logo = this.$imagePreviewUrl + data.logo;
      document.title = this.title
      const link = document.querySelector('link[rel*="icon"]') ||document.createElement('link')
      link.href = this.imagePreviewUrl + data.icon

      this.$store.dispatch('settings/setNavbarBackground', data.navbarBackground ? "background: url("+this.$imagePreviewUrl+data.navbarBackground+") no-repeat;" : "")
      this.$store.dispatch('settings/setSidebarBackground', data.sidebarBackground ? "background-image: url("+this.$imagePreviewUrl+data.sidebarBackground+");" : "")
    });
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      scenicName: '',
      logo: '',
      imagePreviewUrl: this.$imagePreviewUrl,
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  min-width: 240px;
  float: left;
  height: 64px;
  line-height: 64px;
  // background: #2b2f3a;
  // text-align: center;
  margin-left: 24px;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 40px;
      height: 40px;
      vertical-align: middle;
      margin-right: 16px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #747474;
      font-weight: 500;
      line-height: 64px;
      font-size: 20px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
      span {
        &:nth-child(2) {
          font-size: 14px;
          vertical-align: top;
        }
      }
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
