<template>
  <div class="app-container">
    <el-row :gutter="24" class="statistics-container">
      <el-col :span="24" class="statistics-right">
        <div class="statistics">
            <div class="statistics-item bg1">
              <div class="title">待分派</div>
              <div class="statistics-num">
                {{ statisticDate.toBeAssignNum || 0 }}<span style="margin-left:5px;font-size: 16px;color: #919399">单</span>
              </div>
            </div>
            <div class="statistics-item bg2">
              <div class="title">待接单</div>
              <div class="statistics-num">
                {{ parseInt(statisticDate.pendingOrdersNum) || 0 }}<span style="margin-left:5px;font-size: 16px;color: #919399">单</span>
              </div>
            </div>
            <div class="statistics-item bg3">
              <div class="title">办理中</div>
              <div class="statistics-num">
                {{ parseInt(statisticDate.processingNum) || 0 }}<span style="margin-left:5px;font-size: 16px;color: #919399">单</span>
              </div>
            </div>
          
            <div class="statistics-item bg3">
              <div class="title">今日新增</div>
              <div class="statistics-num">
                {{ parseInt(statisticDate.todayNewNum) || 0 }}<span style="margin-left:5px;font-size: 16px;color: #919399">单</span>
              </div>
            </div>
            <div class="statistics-item bg3">
              <div class="title">累计工单</div>
              <div class="statistics-num">
                {{ parseInt(statisticDate.workOrderSum) || 0 }}<span style="margin-left:5px;font-size: 16px;color: #919399">单</span>
              </div>
            </div>
        </div>
      </el-col>
      <el-col :span="12" class="statistics-left">
        <work-type-statistics></work-type-statistics>
      </el-col>
      <el-col :span="12" class="statistics-left">
        <work-level-statistics></work-level-statistics>
      </el-col>
      <el-col :span="24" class="statistics-left">
        <work-order-completion-statistics></work-order-completion-statistics>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {selectWorkOrderNumStatistics} from "../api/biz/workOrder";
import WorkLevelStatistics from './statistics/workLevelStatistics'
import WorkTypeStatistics from './statistics/workTypeStatistics'
import WorkOrderCompletionStatistics from './statistics/workOrderCompletionStatistics'

export default {
  name: 'Index',
  components: {
    WorkLevelStatistics,
    WorkTypeStatistics,
    WorkOrderCompletionStatistics,
  },
  data() {
    return {
      statisticDate:{}
    }
  },
  created() {
  },
  // DOM 渲染完成触发
  mounted() {
    this.selectWorkOrderNumStatisticsHandle()
  },
  methods: {
    selectWorkOrderNumStatisticsHandle(){
      selectWorkOrderNumStatistics().then(res=>{
        this.statisticDate = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-card__header {
  line-height: 12px;
}
.statistics-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -24px;
}

.statistics-left {
  ::v-deep .el-card__header {
    line-height: 12px;
  }
}

.statistics-right {
  ::v-deep .el-row {
    height: 100%;

    .card-box {
      height: 100%;
      padding-bottom: 15px;

      .el-card {
        height: 100%;

        .el-card__body {
          height: 100%;
        }
      }
    }
  }
}
.statistics{
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  .statistics-item{
    margin-right: 24px;
    width: 25%;
    &:last-child{
      margin-right: 0;
    }
  }
}

.day-statistics-box {
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 35px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.color1 {
  ::v-deep .el-card__header {
    background-color: #3281FF;
    color: #ffffff;
    line-height: 12px;
  }
}

.color2 {
  ::v-deep .el-card__header {
    background-color: #00B1FF;
    color: #ffffff;
    line-height: 12px;
  }
}

.color3 {
  ::v-deep .el-card__header {
    background-color: #3AFFF4;
    color: #ffffff;
    line-height: 12px;
  }
}

.color4 {
  ::v-deep .el-card__header {
    background-color: #1F62A3;
    color: #ffffff;
    line-height: 12px;
  }
}

.color5 {
  ::v-deep .el-card__header {
    background-color: #FFB600;
    color: #ffffff;
    line-height: 12px;
  }
}

.color6 {
  ::v-deep .el-card__header {
    background-color: #F54040;
    color: #ffffff;
    line-height: 12px;
  }
}

.color7 {
  ::v-deep .el-card__header {
    background-color: #7B5FEB;
    color: #ffffff;
    line-height: 12px;
  }
}

.color8 {
  ::v-deep .el-card__header {
    background-color: #FF93C4;
    color: #ffffff;
    line-height: 12px;
  }
}


.statistics-item {
  height: 145px;
  //box-shadow: 0 2px 6px 0 rgba(0,67,241,0.08);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
  //border: 1px solid rgba(215,220,227,0.8);
  padding: 32px 24px;
  background-color: #FFFFFF;
  background-image: url("./../assets/images/icon-card-bg-1.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center 90px;

  &.bg1 {
    background-image: url("./../assets/images/icon-card-bg-1.png");
  }

  &.bg2 {
    background-image: url("./../assets/images/icon-card-bg-2.png");
  }

  &.bg3 {
    background-image: url("./../assets/images/icon-card-bg-3.png");
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #485261;
    line-height: 22px;
    margin-bottom: 8px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 16px;
      background: #4897EF;
      border-radius: 1.5px;
      margin-right: 10px;
    }
  }

  .statistics-num {
    font-size: 34px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.9);
    //margin-bottom: 12px;
    text-align: center;
  }

  .statistics-sub-num {
    font-size: 14px;
    line-height: 22px;
    color: #909399;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > span {
      flex: 1;
      margin-right: 10px;

      > span {
        color: #4897EF;
      }
    }
  }
}
</style>
