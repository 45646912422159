<template>
  <div>
    <el-card>
      <div slot="header" class="card-title">
        工单完成情况
        <div style="flex: 1;text-align: right">
          <el-date-picker
            style="margin-left: 15px;"
            v-model="dateRight"
            type="monthrange"
            align="right"
            unlink-panels
            value-format="yyyy-MM"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div style="margin:0 auto;width:100%;height:280px">
        <div id="work-order-completion-statistics" style="width:100%;height:100%"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import echarts from 'echarts'
import {selectWorkOrderStatistics} from "@/api/biz/workOrder";

export default {
  name: 'WorkOrderCompletionStatistics',
  components: {},
  watch: {
    dateRightType() {
      this.dateRight = this.updateDate(this.dateRightType)
    },
    dateRight() {
      this.getData()
    }
  },
  data() {
    return {
      dateRightType: 'sevenDay',
      dateRightTypeArr: [
        { name: '近7天', value: 'sevenDay' },
        { name: '近30天', value: 'thirtyDay' }
      ],
      dateRight: [],
      chartRight: null,
      dataRight: [],
      yearData: []
    }
  },
  mounted() {
    this.dateRight = this.updateDate(this.dateRightType)
  },
  methods: {
    getData() {
      let params = { startDate: this.dateRight[0], endDate: this.dateRight[1] }
      //生成月份
      let month1 = parseInt(params.startDate.split('-')[0] || '0') * 12 + parseInt(params.startDate.split('-')[1] || '0')
      let month2 = parseInt(params.endDate.split('-')[0] || '0') * 12 + parseInt(params.endDate.split('-')[1] || '0')
      let deff = Math.abs(month2 - month1)
      this.yearData = (() => {
        var result = []
        var d = new Date(params.startDate + '-01')
        d.setDate(1)
        d.setMonth(d.getMonth() - 1)
        for (var i = 0; i <= deff; i++) {
          d.setMonth(d.getMonth() + 1)
          var m = d.getMonth() + 1
          m = m < 10 ? '0' + m : m
          //在这里可以自定义输出的日期格式
          result.push(d.getFullYear() + '-' + m)
          //result.push(d.getFullYear() + "年" + m + '月');
        }
        return result
      })()

      selectWorkOrderStatistics(params).then(res => {
        let data = []
        this.yearData.forEach((item) => {
          let value1 = 0, value2 = 0
          for (let i = 0; i < res.data.all.length; i++) {
            let result = res.data.all[i]
            if (result['axisX'] == item) {
              value1 = result.axisY
            }
          }
          for (let i = 0; i < res.data.complete.length; i++) {
            let result = res.data.complete[i]
            if (result['axisX'] == item) {
              value2 = result.axisY
            }
          }
          data.push({ 'axisX': item, 'axisY': value1, 'type': '工单总数' })
          data.push({ 'axisX': item, 'axisY': value2, 'type': '已归档数' })
        })

        this.dataRight = data
        // this.chartRight = this.initChart(this.chartRight, this.dataRight, 'work-order-completion-statistics')
        this.initChart(this.chartRight, this.dataRight, 'work-order-completion-statistics')
      })
    },
    updateDate(dateType) {
      let searchDate = []
      if (dateType == 'thirtyDay') {
        let startDate = new Date()
        startDate.setDate(startDate.getDate() - 30)
        let endDate = new Date()
        endDate.setDate(endDate.getDate() - 1)
        searchDate = [this.dateToStr(startDate), this.dateToStr(endDate)]
      } else if (dateType == 'yesterday') {
        let startDate = new Date()
        startDate.setDate(startDate.getDate() - 1)
        searchDate = [this.dateToStr(startDate), this.dateToStr(startDate)]
      } else if (dateType == 'sevenDay') {
        let startDate = new Date()
        startDate.setDate(startDate.getDate() - 365)
        let endDate = new Date()
        endDate.setDate(endDate.getDate() - 1)
        searchDate = [this.dateToStr(startDate), this.dateToStr(endDate)]
      } else {
        let startDate = new Date()
        searchDate = [this.dateToStr(startDate), this.dateToStr(startDate)]
      }
      return searchDate
    },
    dateToStr(date) {
      var year = date.getFullYear()//年
      var month = date.getMonth()//月
      return year + '-' + ((month + 1) > 9 ? (month + 1) : '0' + (month + 1))
    },
    initChart(chart, data, chartName) {
      // if (!chart) {
      //   chart = new Line(chartName, {
      //     data,
      //     yField: 'axisY',
      //     xField: 'axisX',
      //     seriesField: 'type',
      //     color: ['#4D88FE', '#50CCCB'],
      //     smooth: true
      //   })
      //   chart.render()
      // } else {
      //   chart.update({
      //     data,
      //     yField: 'axisY',
      //     xField: 'axisX',
      //     seriesField: 'type',
      //     color: ['#4D88FE', '#50CCCB'],
      //     smooth: true
      //   })
      // }
      //
      // return chart


      let map = new Map();
      data.forEach((item) => {
        if (map.has(item.type)) {
          map.set(item.type, [...map.get(item.type), item])
        } else {
          map.set(item.type, [item])
        }
      });
      const chartDom = document.getElementById(chartName)
      const myChart = echarts.init(chartDom)
      let option = null

      const color = [
        '#0061FC',
        '#18A383',
        '#741FE0',
        '#6873E6',
        '#FC9C00',
        '#FF492E',
        '#DF4399',
        '#0048BA',
        '#D3DC09',
        '#485261'
      ]

      option = {
        color: color,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(128, 128, 128, 0.05)'
            }
          },
          backgroundColor: 'rgba(255,255,255,0.95)',
          extraCssText: 'box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);',
          textStyle: {
            fontSize: 14,
            color: '#303133'
          }
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '5%',
          bottom: 0,
          containLabel: true
        },
        legend: {
          // orient: 'vertical',
          // right: 'right',
          // type: 'scroll',
          x: 'center',
          y: 'top',
          itemWidth: 15,
          itemHeight: 10,
          // icon: 'rect',
          textStyle: {
            fontSize: 14,
            color: '#303133'
          },
          data: [ '工单总数', '已归档数'],
        },
        xAxis: {
          type: 'category',
          axisTick: {
            show: true,
            lineStyle: {
              color: '#E9E9E9'
            }
          },
          axisLabel: {
            fontSize: 12,
            color: '#41434B'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E9E9E9'
            }
          },
          data: [...map][0][1].map(i => i.axisX)
        },
        yAxis: {
          name:"工单数量（个）",
          nameTextStyle: {
            fontSize: 14,
            color: '#41434B'
          },
          type: 'value',
          axisLabel: {
            fontSize: 12,
            color: '#41434B',
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#E9E9E9',
              type: 'dashed'
            }
          },
        },
        series: [...map].map((item, index) => {
          if(index==0){
            return {
              type: 'line',
              name: item[0],
              data: item[1].map(i=>{
                return {
                  name: i.axisX,
                  value: i.axisY
                }
              }),
              smooth: true,
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: color[index] + '15', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: color[index] + '00', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            }
          }else{
            return {
              type: 'bar',
              barMaxWidth: 20,
              name: item[0],
              data: item[1].map(i=>{
                return {
                  name: i.axisX,
                  value: i.axisY
                }
              }),
            }
          }
        })
      }

      option && myChart.setOption(option)
    }
  }
}
</script>
