<template>
    <work-order-detail :workOrderNo="workOrderNo" v-if="showWorkOrderDetail" :show.sync="showWorkOrderDetail" :detailType="detailType" :fromSource="fromSource" :uuid="uuid" :fullscreen="true" :show-close="false" :modal="false"></work-order-detail>
</template>

<script>
import WorkOrderDetail from "@/views/biz/workOrder/info";

export default {
  name: "WorkOrder",
  components: {WorkOrderDetail},
  data() {
    return {
      workOrderNo:null,
      showWorkOrderDetail: false,
      detailType:"detail",
      fromSource:"",
      uuid:""
    };
  },
  created() {
    this.detailType = this.$route.query.detailType;
    this.uuid = this.$route.query.uuid;
    this.workOrderNo = this.$route.query.workOrderNo;
    this.fromSource = this.$route.query.fromSource;
    this.showWorkOrderDetail = true;
  },
  methods: {


  }
};
</script>
<style scoped lang="scss">
.el-date-editor, .el-select{
  width: 100%;
}
</style>
