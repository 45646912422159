import request from '@/utils/request'

// 查询工单列表
export function listWorkOrder(query) {
  return request({
    url: '/biz/workOrder/list',
    method: 'get',
    params: query
  })
}

//查询工单分类报表列表
export function sortlistWorkOrder(query) {
  return request({
    url: '/biz/workOrder/sortList',
    method: 'get',
    params: query
  })
}
//查询工单状态报表列表
export function statuslistWorkOrder(query) {
  return request({
    url: '/biz/workOrder/statusList',
    method: 'get',
    params: query
  })
}

// 删除工单
export function delWorkOrder(workOrderId) {
  return request({
    url: '/biz/workOrder/' + workOrderId,
    method: 'delete'
  })
}

// 查询我发起的工单列表
export function selectMyWorkOrderList(query) {
  return request({
    url: '/biz/workOrder/selectMyWorkOrderList',
    method: 'get',
    params: query
  })
}

// 查询待分配的工单列表
export function selectAssignWorkOrderList(query) {
  return request({
    url: '/biz/workOrder/selectAssignWorkOrderList',
    method: 'get',
    params: query
  })
}

// 查询待分配的工单列表
export function selectBackWorkOrderList(query) {
  return request({
    url: '/biz/workOrder/selectBackWorkOrderList',
    method: 'get',
    params: query
  })
}

// 查询待接单的工单列表
export function selectUnAcceptWorkOrderList(query) {
  return request({
    url: '/biz/workOrder/selectUnAcceptWorkOrderList',
    method: 'get',
    params: query
  })
}

// 查询审核的工单列表
export function selectApprovalWorkOrderList(query) {
  return request({
    url: '/biz/workOrder/selectApprovalWorkOrderList',
    method: 'get',
    params: query
  })
}

// 查询的归档工单列表
export function selectArchiveWorkOrderList(query) {
  return request({
    url: '/biz/workOrder/selectArchiveWorkOrderList',
    method: 'get',
    params: query
  })
}

// 查询待反馈的工单列表
export function selectProcessingWorkOrderList(query) {
  return request({
    url: '/biz/workOrder/selectProcessingWorkOrderList',
    method: 'get',
    params: query
  })
}

// 查询工单详细
export function getWorkOrder(workOrderNo) {
  return request({
    url: '/biz/workOrder/getWorkOrderDetail',
    method: 'get',
    params: {workOrderNo}
  })
}


// 根据经纬度获取当前地址
export function getAddress(query) {
  return request({
    url: '/biz/workOrder/getAddress',
    method: 'get',
    params: query
  })
}


// 新增工单
export function addWorkOrder(data) {
  return request({
    url: '/biz/workOrder/addWorkOrder',
    method: 'post',
    data: data
  })
}

// 分派工单处理人
export function addWorkOrderAssign(data) {
  return request({
    url: '/biz/workOrder/addWorkOrderAssign',
    method: 'post',
    data: data
  })
}

// 转派工单处理人
export function transferWorkOrderAssign(data) {
  return request({
    url: '/biz/workOrder/transferWorkOrderAssign',
    method: 'post',
    data: data
  })
}

// 接单
export function workOrderAccept(data) {
  return request({
    url: '/biz/workOrder/workOrderAccept',
    method: 'post',
    data: data
  })
}

// 工单退回
export function workOrderBack(data) {
  return request({
    url: '/biz/workOrder/workOrderBack',
    method: 'post',
    data: data
  })
}

// 退回审核
export function workOrderBackApproval(data) {
  return request({
    url: '/biz/workOrder/workOrderBackApproval',
    method: 'post',
    data: data
  })
}

// 工单审批
export function workOrderApproval(data) {
  return request({
    url: '/biz/workOrder/workOrderApproval',
    method: 'post',
    data: data
  })
}

// 取消工单
export function cancelWorkOrder(data) {
  return request({
    url: '/biz/workOrder/cancelWorkOrder',
    method: 'post',
    data: data
  })
}


// 查询工单数量统计
export function selectWorkOrderNumStatistics(data) {
  return request({
    url: '/biz/workOrder/selectWorkOrderNumStatistics',
    method: 'post',
    data: data
  })
}

// 查询工单级别统计
export function selectWorkOrderLevelStatistics(data) {
  return request({
    url: '/biz/workOrder/selectWorkOrderLevelStatistics',
    method: 'post',
    data: data
  })
}

// 查询工单级别统计
export function selectWorkOrderTypeStatistics(data) {
  return request({
    url: '/biz/workOrder/selectWorkOrderTypeStatistics',
    method: 'post',
    data: data
  })
}

// 查询工单统计
export function selectWorkOrderStatistics(data) {
  return request({
    url: '/biz/workOrder/selectWorkOrderStatistics',
    method: 'post',
    data: data
  })
}

//修改工单
export function updateOrder(data) {
  return request({
    url: '/biz/workOrder',
    method: 'put',
    data: data
  })
}



