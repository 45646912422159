<template>
  <div :id="id" :style="'width:100%; height:'+height+'; margin-bottom: 20px'"></div>
</template>
<script>
export default {
  components: {},
  props:{
    longitude:{
      type:String,
    },
    latitude:{
      type:String,
    },
    zoom:{
      default:10.8,
      type:Number,
    },
    id:{
      type:String,
    },
    height:{
      default:"600px",
      type:String,
    }
  },
  data(){
    return{
      temLng:this.longitude,
      temLat:this.latitude,
      markerLayerArr:{},
      domMarkerArr:{},
      polylineArr:{},
      polygonArr:{},
      defaultMarkerLayer: '',
      //defaultLongitude:117.296607,
      //defaultLatitude:31.779661,
      defaultLongitude:117.206000,
      defaultLatitude:29.295291,
      map:null,
      editor:null
    }
  },
  watch:{
    longitude:function(){
      this.temLng = this.longitude
    },
    latitude:function(){
      this.temLat = this.latitude
    },
  },
  methods: {
    /**
     * 初始化地图
     */
    initMap() {
      if(!this.map){
        document.getElementById(this.id).innerHTML=''
        if(!this.longitude || !this.latitude){
          this.temLng = this.defaultLongitude;
          this.temLat = this.defaultLatitude;
        }
        let center = new TMap.LatLng(this.temLat, this.temLng)
        //定义map变量，调用 TMap.Map() 构造函数创建地图
        this.map = new TMap.Map(this.id, {
          center: center,//设置地图中心点坐标
          zoom: this.zoom,   //设置地图缩放级别
        });
        //默认点位图层
        this.defaultMarkerLayer = new TMap.MultiMarker({
          map: this.map,
          styles: {
            // 点标记样式
            defaultStyle: new TMap.MarkerStyle({
              width: 50, // 样式宽
              height: 78, // 样式高
              src:require("@/assets/icons/png/mark_icon.png"),
              anchor: { x: 25, y: 72 }, // 描点位置
            }),
          },
        });
        this.markerLayerArr.defaultMarkerLayer = this.defaultMarkerLayer;
        this.map.on("click",(evt)=>{
          let lat = evt.latLng.getLat().toFixed(6);
          let lng = evt.latLng.getLng().toFixed(6);
          let latArr={
            longitude:lng,
            latitude:lat
          }

          this.$emit('mapClickEvent',latArr);
        })

        //手绘图南西
        /*let imageSW = new TMap.LatLng(37.8893752,120.677908);
        //北东
        let imageNE = new TMap.LatLng(37.9966992,120.775909);
        let imageLatLngBounds = new TMap.LatLngBounds(imageSW, imageNE);
        new TMap.ImageGroundLayer({
          bounds: imageLatLngBounds,
          src: require("/static/image/carimg/WechatIMG826.png"),
          map: this.map,
          zIndex:100,
          opacity:0.5
        });*/
      }else{
        if(!this.longitude || !this.latitude){
          this.temLng = this.defaultLongitude;
          this.temLat = this.defaultLatitude;
        }
        this.map.setZoom(this.zoom);
        this.setCenter(this.temLng,this.temLat)
      }
    },
    /**
     * 点位移动
     * @param id
     * @param lat
     * @param lng
     * @param layerId
     */
    moveMarker(id,lat,lng,layerId){
      let markerLayer = this.defaultMarkerLayer;
      if(layerId){
        markerLayer = this.markerLayerArr["markerLayer_"+markerLayer];
      }

      markerLayer.updateGeometries([
        {
          "id": id,
          "position": new TMap.LatLng(lat, lng),
        }
      ])
    },
    /**
     * 增加点位图层
     * @param id
     * @param src
     * @param width
     * @param height
     * @param anchor
     * @returns {TMap.MultiMarker}
     */
    addMarkerLayer(id,src,width,height,anchor){
      let markerLayer = new TMap.MultiMarker({
        map: this.map,
        styles: {
          // 点标记样式
          defaultStyle: new TMap.MarkerStyle({
            id:id,
            width: width, // 样式宽
            height: height, // 样式高
            src:src,
            anchor: anchor?anchor:{ x: 10, y: 30 }, // 描点位置
          }),
        },
      });
      this.markerLayerArr["markerLayer_"+id] = markerLayer;
      return markerLayer;
    },
    /**
     * 增加点位
     * @param markOptions={
     *   isSingle:是否单个点位
     *   isCenter:是否设置中心
     *   id:定位唯一id
     *   longitude:经度
     *   latitude:纬度
     *   markerLayerId:点位图层id，与point区分，不为空时point无效，markerLayerId与point同时为空时，则默认图片，不传默认图层（自定义图层需要先创建图层addMarkerLayer）
     *   point:点位dom对象，markerLayerId不为空时point无效，markerLayerId与point同时为空时，则默认图片
     * }
     */
    addMarker(markOptions){
      if(markOptions.latitude && markOptions.longitude){
        let position = new TMap.LatLng(markOptions.latitude,markOptions.longitude);
        //是否多个点位
        markOptions.map = this.map;
        if(markOptions.isSingle){
          this.removeAllMarker(markOptions.markerLayerId);
        }

        //是否设置中心
        if(markOptions.isCenter){
          markOptions.map.setCenter(position);
        }

        if(markOptions.markerLayerId && !markOptions.point){
          //增加自定义图层点位
          let markOptions = {
            "position": position,  //点标记坐标位置
          }
          markOptions.id?markOptions.id=markOptions.id:null;   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          this.markerLayerArr["markerLayer_"+markOptions.markerLayerId].add([markOptions]);
        }else if(markOptions.point){
          //增加自定义domMark
          return this.addDomMark(markOptions);
        }else{
          //增加默认图层点位
          let markOptions = {
            "styleId": 'defaultStyle',  //指定样式id
            "position": position,  //点标记坐标位置
          }
          markOptions.id?markOptions.id=markOptions.id:null;   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          this.defaultMarkerLayer.add([markOptions]);
        }
      }
    },
    /**
     * 增加dom点位
     * @param markOptions
     * @returns {Donut}
     */
    addDomMark(markOptions){
      let position = new TMap.LatLng(markOptions.latitude, markOptions.longitude);
      //自定义DOM覆盖物 - 继承DOMOverlay
      function Donut(options) {
        TMap.DOMOverlay.call(this, options);
      }
      Donut.prototype = new TMap.DOMOverlay();

      // 初始化
      Donut.prototype.onInit = function(options) {
        this.position = options.position;
      };

      // 创建DOM元素，返回一个DOMElement
      Donut.prototype.createDOM = function() {
        return markOptions.point;
      };

      // 销毁时需解绑事件监听
      Donut.prototype.onDestroy = function() {
        if (this.onClick) {
          this.dom.removeEventListener(this.onClick);
        }
      };

      // 更新DOM元素，在地图移动/缩放后执行
      Donut.prototype.updateDOM = function() {
        if (!this.map) {
          return;
        }

        // 经纬度坐标转容器像素坐标
        let pixel = this.map.projectToContainer(this.position);

        // 使饼图中心点对齐经纬度坐标点
        let left = pixel.getX() - this.dom.clientWidth / 2 + 'px';
        let top = pixel.getY() - this.dom.clientHeight + 'px';
        this.dom.style.transform = `translate(${left}, ${top})`;
      };

      let domMarkerKey = markOptions.id?markOptions.markerLayerId+"domMarker_"+markOptions.id:null;
      let domMarker = new Donut({
        id:domMarkerKey,
        map:this.map,
        enableCustom: true,
        position: position,
        offset: markOptions.offset?markOptions.offset:{ y: 0, x: 0 },
      })
      domMarkerKey = domMarkerKey?domMarkerKey:domMarker.id;
      this.domMarkerArr[domMarkerKey] = domMarker;
      return domMarker;
    },
    /**
     * 去除指定点位
     * @param id
     * @param layerId
     */
    removeMarker(id,layerId){
      //去除图层点位
      if(!layerId){
        this.defaultMarkerLayer.remove([id]);
      }else{
        let markerLayer = this.markerLayerArr["markerLayer_"+id];
        if(markerLayer){
          markerLayer.remove([id]);
          delete this.markerLayerArr["markerLayer_"+id];
        }
      }

      //去除domMarker
      let domMarker = this.domMarkerArr[layerId+"domMarker_"+id];
      if(domMarker){
        domMarker.setMap(null);
        domMarker.destroy();
        delete this.domMarkerArr[layerId+"domMarker_"+id];
      }
    },
    /**
     * 清空所有点位
     */
    removeAllMarker(layerId){
      //清空图层点位
      if(layerId){
        if(this.markerLayerArr["markerLayer_"+layerId]){
          this.markerLayerArr["markerLayer_"+layerId].setGeometries([]);
          delete this.markerLayerArr["markerLayer_"+layerId];
        }
      }else{
        for(let i in this.markerLayerArr){
          this.markerLayerArr[i].setGeometries([]);
        }
        this.defaultMarkerLayer.setGeometries([]);
        this.markerLayerArr = {};
      }

      if(layerId) {
        for (let i in this.domMarkerArr) {
          if(i.indexOf(layerId+"domMarker_")>-1){
            this.domMarkerArr[i].setMap(null);
            this.domMarkerArr[i].destroy();
            delete this.domMarkerArr[i];
          }
        }
      }else{
        //清空domMarker
        for (let i in this.domMarkerArr) {
          if(i.indexOf("domMarker_")<0){
            this.domMarkerArr[i].setMap(null);
            this.domMarkerArr[i].destroy();
            delete this.domMarkerArr[i];
          }
        }
      }
    },
    /**
     * 设置地图中心
     * @param longitude
     * @param latitude
     */
    setCenter(longitude,latitude){
      this.map.setCenter(new TMap.LatLng(latitude, longitude));
    },
    /**
     * 初始化信息窗口
     * @returns {Window.TMap.InfoWindow}
     */
    initInfoWindow(){
      let infoWindow = new window.TMap.InfoWindow({
        map: this.map,
        enableCustom: true,
        position: new window.TMap.LatLng(this.defaultLatitude, this.defaultLongitude),
        offset: { y: 0, x: 0 },
        content:'<div></div>',
      });
      infoWindow.close();
      return infoWindow;
    },
    /**
     * 显示信息窗口
     * @param infoOptions
     */
    showInfoWindow(infoOptions){
      infoOptions.infoWindow.setPosition(new TMap.LatLng(infoOptions.latitude, infoOptions.longitude));//设置信息窗位置
      infoOptions.infoWindow.setContent(infoOptions.content);//设置信息窗内容
      infoOptions.infoWindow.open();
    },
    /**
     * 添加路线
     * @param lineOptions={
     *          pointArr:路线点数组
     *          id：路线图层id
     *          width：路线宽度
     *          color：路线颜色
     * }
     */
    addLine(lineOptions){
      if(lineOptions.pointArr.length<2){
        return;
      }

      let path = [];
      lineOptions.pointArr.forEach(point=>{
        path.push(new TMap.LatLng(point[1], point[0]));
      })

      let polylineId = lineOptions.id?'polyline_'+lineOptions.id:null;
      //初始化折线，设置图层id，指定显示在id为map的地图上
      let polylineLayer = new TMap.MultiPolyline({
        id: polylineId, //设置图层唯一id
        map: this.map,//指定绘制到的地图id
        zIndex:1,
        //自定义两组样式
        styles: {
          //第一组样式
          'default_style': new TMap.PolylineStyle({
            color: lineOptions.color?lineOptions.color:'#3777FF', //线填充色
            width: lineOptions.width?lineOptions.width:6, //折线宽度
            borderWidth: 2, //边线宽度
            borderColor: '#FFF', //边线颜色
            lineCap: 'butt' //线端头方式
          })
        },

        //设置折线的数据，并为每条线配置不同的样式
        geometries: [
          {//第1条线
            styleId: 'default_style',//绑定样式id,对应上面第一组样式
            paths: path //传入折线的数据，其中100表示距离地面100像素
          }
        ]
      });

      this.polylineArr[polylineId] = polylineLayer;
      return polylineLayer;
    },
    /**
     * 删除线路
     * @param polylineId
     */
    removeLine(polylineId){
      this.removeAllLine(polylineId);
    },
    /**
     * 删除所有路线
     * @param polylineId
     */
    removeAllLine(polylineId){
      if(polylineId){
        for (let i in this.polylineArr) {
          if(i.indexOf('polyline_'+polylineId)>-1){
            this.polylineArr[i].setMap(null);
            this.polylineArr[i].destroy();
            delete this.polylineArr[i];
          }
        }
      }else{
        for (let i in this.polylineArr) {
          if(i.indexOf('polyline_'+polylineId)<0) {
            this.polylineArr[i].setMap(null);
            this.polylineArr[i].destroy();
            delete this.polylineArr[i];
          }
        }
      }
    },
    /**
     * 新增多边形
     * @param polygonOpt
     * @returns {*}
     */
    addMultiPolygon(polygonOpt){
      let paths = []
      let geometries = null;
      if(polygonOpt.polygon && polygonOpt.polygon.length>0){
        polygonOpt.polygon.forEach(item=>{
          paths.push(new TMap.LatLng(item[1], item[0]));
        })

        geometries = [
          {
            'styleId': 'polygon', //绑定样式名
            'paths': paths, //多边形轮廓
          }
        ];
      }
      //初始化polygon
      let polygonKey = polygonOpt.id?"polygon_"+polygonOpt.id:null;
      let polygon = new TMap.MultiPolygon({
        id: polygonKey, //图层id
        map: this.map, //设置多边形图层显示到哪个地图实例中
        //多边形样式
        styles: {
          'polygon': new TMap.PolygonStyle({
            'color': polygonOpt.color?polygonOpt.color:'rgba(41,91,255,0.3)', //面填充色
            'showBorder':true, //是否显示拔起面的边线
            'borderWidth': 2,
            'borderColor': polygonOpt.color?polygonOpt.color:'rgba(41,91,255,0.9)' //边线颜色
          })
        },
        //多边形数据
        geometries:geometries
      });

      //console.log(polygon)
      this.polygonArr[polygonKey?polygonKey:polygon.id] = polygon;
      return polygon;
    },
    /**
     * 删除多边形
     * @param polygonId
     */
    removePolygon(polygonId){
      this.removeAllPolygon(polygonId);
    },
    /**
     * 删除所有多边形
     * @param polygonId
     */
    removeAllPolygon(polygonId){
      if(polygonId){
        for (let i in this.polylineArr) {
          if(i.indexOf('polygon_'+polygonId)>-1){
            this.polygonArr[i].setMap(null);
            delete this.polygonArr[i];
          }
        }
      }else{
        for (let i in this.polygonArr) {
          if(i.indexOf('polygon_'+polygonId)<0) {
            //console.log(this.polygonArr[i]);
            this.polygonArr[i].setMap(null);
            delete this.polygonArr[i];
          }
        }
      }
    },
    addEditor(editorOpt){
      if(!this.editor){
        // 初始化几何图形及编辑器
        let editor = new TMap.tools.GeometryEditor({
          map:this.map,
          overlayList: [ // 可编辑图层
            {
              overlay: editorOpt.overlay,
              id: 'polygon',
            }
          ],
          actionMode: TMap.tools.constants.EDITOR_ACTION.INTERACT, // 编辑器的工作模式
          activeOverlayId: 'polygon', // 激活图层
          selectable: true, // 开启点选功能
          snappable: true // 开启吸附
        });

        // 监听删除、修改、拆分、合并完成事件
        let evtList = ['delete', 'adjust', 'split', 'union','draw'];
        evtList.forEach(evtName => {
          editor.on(evtName + '_complete', evtResult => {
            //console.log(evtResult.paths);
            //console.log(evtName);
            this.$emit('drawUpdate',evtResult.paths);
          });
        });

        // 监听拆分失败事件，获取拆分失败原因
        editor.on('split_fail', (res) => {

        });
        // 监听合并失败事件，获取合并失败原因
        editor.on('union_fail', (res) => {

        });

        this.editor = editor;
      }else{
        this.editor.removeOverlay('polygon');
        this.editor.addOverlay({
          overlay: editorOpt.overlay,
          id: 'polygon',
        });
      }
    },
    switchDraw(type){
      if("draw"==type){
        this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW);
      }else{
        this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT);
      }
    }
  }
}
</script>
