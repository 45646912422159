var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-upload-image" },
    [
      _c(
        "el-upload",
        {
          class: { hide: this.fileList.length >= this.limit },
          attrs: {
            multiple: "",
            action: _vm.uploadImgUrl,
            "list-type": "picture-card",
            "on-success": _vm.handleUploadSuccess,
            "before-upload": _vm.handleBeforeUpload,
            limit: _vm.limit,
            "on-error": _vm.handleUploadError,
            "on-exceed": _vm.handleExceed,
            name: "file",
            "on-remove": _vm.handleRemove,
            "show-file-list": true,
            headers: _vm.headers,
            data: { path: "img" },
            "file-list": _vm.fileList,
            "on-preview": _vm.handlePictureCardPreview,
          },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _vm.showTip && !_vm.disabled
        ? _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [
              _vm._v(" 请上传 "),
              _vm.fileSize
                ? [
                    _vm._v(" 大小不超过 "),
                    _c("b", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v(_vm._s(_vm.fileSize) + "MB"),
                    ]),
                  ]
                : _vm._e(),
              _vm.fileType
                ? [
                    _vm._v(" 格式为 "),
                    _c("b", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v(_vm._s(_vm.fileType.join("/"))),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" 的文件 "),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "预览",
            width: "800",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: {
              display: "block",
              "max-width": "100%",
              margin: "0 auto",
            },
            attrs: { src: _vm.dialogImageUrl },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }