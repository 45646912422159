import cache from '@/plugins/cache'

const TokenKey = 'Admin-Token'

export function getToken() {
  return cache.local.get(TokenKey)
}

export function getTokenExp() {
  return cache.local.get(TokenKey+"exp")
}

export function setToken(token, exp) {
  cache.local.set(TokenKey+"exp", exp)
  return cache.local.set(TokenKey, token)
}

export function removeToken() {
  cache.local.remove(TokenKey+"exp")
  return cache.local.remove(TokenKey)
}
