<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title:
        this.$store.state.settings.dynamicTitle &&
        this.$store.state.settings.title,
      titleTemplate: (title) => {
        return title
          ? `${title} - ${process.env.VUE_APP_TITLE}`
          : process.env.VUE_APP_TITLE;
      },
    };
  },
};
</script>
<style>
/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 6px;
}
/* // 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 3px;
}

/* // 修改表格(el-table)的滚动条 */
.el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* // 滚动条的滑块 */
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}
</style>
