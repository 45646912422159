var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.user, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "登录账号", prop: "userName" } },
        [
          _c("el-input", {
            attrs: { size: "medium", disabled: "" },
            model: {
              value: _vm.user.userName,
              callback: function ($$v) {
                _vm.$set(_vm.user, "userName", $$v)
              },
              expression: "user.userName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "用户名称", prop: "personName" } },
        [
          _c("el-input", {
            attrs: { size: "medium" },
            model: {
              value: _vm.user.personName,
              callback: function ($$v) {
                _vm.$set(_vm.user, "personName", $$v)
              },
              expression: "user.personName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "手机号码", prop: "phone" } },
        [
          _c("el-input", {
            attrs: { maxlength: "11", size: "medium" },
            model: {
              value: _vm.user.phone,
              callback: function ($$v) {
                _vm.$set(_vm.user, "phone", $$v)
              },
              expression: "user.phone",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "邮箱", prop: "email" } },
        [
          _c("el-input", {
            attrs: { maxlength: "50", size: "medium" },
            model: {
              value: _vm.user.email,
              callback: function ($$v) {
                _vm.$set(_vm.user, "email", $$v)
              },
              expression: "user.email",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "性别" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.user.sex,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "sex", $$v)
                },
                expression: "user.sex",
              },
            },
            [
              _c("el-radio", { attrs: { label: "0" } }, [_vm._v("男")]),
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("女")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }