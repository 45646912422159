<template>
  <div class="app-container">
    <el-dialog :visible.sync="_show" :close-on-click-modal="false" :width="dialogWidth" :fullscreen="fullscreen" :show-close="showClose" :modal="modal" center>
      <el-row>
        <el-col :span="17">
          <el-descriptions size="medium" title="任务信息" :column="2">
            <el-descriptions-item label="工单编号">{{ workOrderDetail.workOrderNo }}</el-descriptions-item>
            <el-descriptions-item label="任务名称">
              <el-input v-model="workOrderDetail.workOrderName" placeholder="请输入任务名称" v-if="isUpdateStatus"></el-input>
              <span v-else>{{ workOrderDetail.workOrderName }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="任务级别">
             <el-select
              v-model="workOrderDetail.workOrderLevel"
              placeholder="任务级别"
              clearable
              style="width: 240px"
              v-if="isUpdateStatus"

             >
              <el-option
               v-for="dict in dict.type.work_order_level"
               :key="dict.value"
               :label="dict.label"
               :value="dict.value"
              />
             </el-select>
              <dict-tag :options="dict.type.work_order_level" :value="workOrderDetail.workOrderLevel" v-else/>
             </el-descriptions-item>
             <el-descriptions-item label="任务类型">
              <el-select
               v-model="workOrderDetail.workOrderType"
               placeholder="任务类型"
               clearable
               style="width: 240px"
               v-if="isUpdateStatus"
              >
               <el-option
                v-for="dict in dict.type.work_order_type"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
               />
              </el-select>
               <dict-tag :options="dict.type.work_order_type" :value="workOrderDetail.workOrderType" v-else/>
             </el-descriptions-item>
            <el-descriptions-item label="任务状态"><dict-tag :options="dict.type.work_order_status" :value="workOrderDetail.workOrderStatus"/></el-descriptions-item>
            <el-descriptions-item label="任务描述">
              <el-input v-model="workOrderDetail.description" placeholder="请输入任务描述" v-if="isUpdateStatus" type="textarea"></el-input>
              <span v-else>{{ workOrderDetail.description }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="发生地点">
              <el-input v-model="workOrderDetail.address" placeholder="请输入发生地点" v-if="isUpdateStatus"></el-input>
              <span v-else>{{ workOrderDetail.address }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="发生时间">
              <el-date-picker clearable size="small" style="width: 200px"
                              v-if="isUpdateStatus"
                              v-model="workOrderDetail.workOrderTime"
                              type="date"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="选择发生时间">
              </el-date-picker>
              <span v-else>{{ parseTime(workOrderDetail.workOrderTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="发起人">{{ workOrderDetail.launchPersonName }}({{ workOrderDetail.launchPersonPhone }})</el-descriptions-item>
            <el-descriptions-item label="附件">
              <file-upload class="file-show-container" v-if="(workOrderDetail.fileList && workOrderDetail.fileList.length > 0)|| isUpdateStatus" v-model="workOrderDetail.fileList" :disabled="!isUpdateStatus" :fileType="['png','jpg','jpeg','mp4']" :fileSize="100" valueType="object"/>
              <span v-else>无</span>
            </el-descriptions-item>
          </el-descriptions>
        </el-col>

        <el-col :span="5">
          <div style="padding-top: 20px">
            <qq-map ref="map" :id="'deviceAlarmContainer'" :height="'200px'"></qq-map>
<!--            <gis-map :id="'enterpriseContainer'" ref="gismap" v-model="map" :zoom="13.5" v-if="_show" :height="'200px'"></gis-map>-->
          </div>
        </el-col>
        <el-col :span="24">
          <el-descriptions title="处理信息"></el-descriptions>
          <el-table :data="workOrderProcessList">
            <el-table-column label="处理环节" align="center" prop="processNode">
              <template slot-scope="scope">
                <dict-tag :options="dict.type.work_order_process_node" :value="scope.row.processNode"/>
              </template>
            </el-table-column>
            <el-table-column label="处理时间" align="center" prop="createTime"/>
            <el-table-column label="处理单位" align="center" prop="processDeptName"/>
            <el-table-column label="处理人" align="center" prop="processPersonName"/>
            <el-table-column label="处理意见" align="center" prop="description" />
            <el-table-column label="附件" align="center" prop="fileList" min-width="120">
              <template slot-scope="scope">
                <div style="margin: 10px 0" v-if="scope.row.fileList && scope.row.fileList.length > 0">
                  <file-upload class="file-show-container" v-model="scope.row.fileList" :disabled="true"/>
                </div>
                <span v-else>无</span>
              </template>
            </el-table-column>
          </el-table>

        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" v-if="workOrderDetail.workOrderStatus =='0' && detailType == 'assign'">
          <el-descriptions style="margin-top: 50px" title="工单分派"></el-descriptions>
          <el-form ref="fromAssign" :model="fromAssign" label-width="80px">
            <el-form-item label="分派单位">
              <div v-for="assign in assignList">{{assign.processDeptName}} - {{assign.processPersonName}}</div>
              <el-button size="mini" type="primary" @click="onSelectUsers">分派</el-button>
            </el-form-item>
            <el-form-item label="分派意见" prop="description">
              <el-input type="textarea" :rows="6" v-model="fromAssign.description" placeholder="请输入分派意见" />
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submitFormAssign">确 定</el-button>
          </div>
        </el-col>
        <el-col :span="24" v-if="workOrderDetail.workOrderStatus =='1' && detailType == 'transfer'">
          <el-descriptions style="margin-top: 50px" title="工单转派"></el-descriptions>
          <el-form ref="fromAssign" :model="fromAssign" label-width="80px">
            <el-form-item label="转派单位">
              <div v-for="assign in assignList">{{assign.processDeptName}} - {{assign.processPersonName}}</div>
              <el-button size="mini" type="primary" @click="onSelectUsers">转派</el-button>
            </el-form-item>
            <el-form-item label="转派意见" prop="description">
              <el-input type="textarea" :rows="6" v-model="fromAssign.description" placeholder="请输入转派意见" />
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submitFormTransfer">确 定</el-button>
          </div>
        </el-col>
        <el-col :span="24" v-if="workOrderDetail.workOrderStatus =='2' && detailType == 'backApproval'">
          <el-descriptions title="退回审核"></el-descriptions>
          <el-form ref="form" :model="fromBackApproval" label-width="80px">
            <el-form-item label="审核结果" prop="approvalResult">
              <el-radio-group v-model="fromBackApproval.approvalResult">
                <el-radio label="1">转办</el-radio>
                <el-radio label="2">打回</el-radio>
                <el-radio label="3">归档</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="转派单位" prop="workOrderName" v-show="fromBackApproval.approvalResult == '1'">
              <div v-for="assign in assignList">{{assign.processDeptName}} - {{assign.processPersonName}}</div>
              <el-button size="mini" type="primary" @click="onSelectUsers">分派</el-button>
            </el-form-item>
            <el-form-item label="审核意见" prop="description">
              <el-input type="textarea" :rows="6" v-model="fromBackApproval.description" placeholder="请输入审核意见" />
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submitFormBackApproval">确 定</el-button>
          </div>
        </el-col>
        <el-col :span="24" v-if="workOrderDetail.workOrderStatus =='4' && detailType == 'approval'">
          <el-descriptions title="处置结果复核"></el-descriptions>
          <el-form ref="form" :model="fromApproval" label-width="80px">
            <el-form-item label="复核结果" prop="approvalResult">
              <el-radio-group v-model="fromApproval.approvalResult">
                <el-radio label="1">通过</el-radio>
                <el-radio label="2">不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="复核意见" prop="description">
              <el-input type="textarea" :rows="6" v-model="fromApproval.description" placeholder="请输入复核意见" />
            </el-form-item>
            <el-form-item label="附件上传" prop="fileList">
              <file-upload v-model="fromApproval.fileList"  valueType="object" :fileType="['png','jpg','jpeg','mp4']" :fileSize="100"/>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submitFormApproval">确 定</el-button>
          </div>
        </el-col>
        <el-col :span="24" v-if="workOrderDetail.workOrderStatus =='1' && detailType == 'workOrderAccept'">
<!--          <el-descriptions title="接单处理" style="margin-top: 20px;"></el-descriptions>
          <div style="text-align: center">
            <el-button type="primary" @click="submitFormApproval">接 单</el-button>
          </div>-->
        </el-col>
      </el-row>

      <div v-if="isUpdateStatus" style="float: right;margin-top: 20px">
        <el-button type="primary" @click="comfirmUpdate">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 候选用户弹窗 -->
    <el-dialog title="候选用户" :visible.sync="userOpen" width="60%">
      <el-row type="flex" :gutter="20" v-if="userOpen">
        <!--部门数据-->
        <el-col :span="7">
          <el-card shadow="never" style="height: 600px;overflow: auto;">
            <div slot="header">
              <span>部门列表</span>
            </div>
            <div class="head-container">
              <el-input
                v-model="deptName"
                placeholder="请输入部门名称"
                clearable
                size="small"
                prefix-icon="el-icon-search"
                style="margin-bottom: 20px"
              />
              <el-tree
                :data="deptOptions"
                :props="deptProps"
                :expand-on-click-node="false"
                :filter-node-method="filterNode"
                ref="tree"
                default-expand-all
                @node-click="handleNodeClick"
              />
            </div>
          </el-card>
        </el-col>
        <el-col :span="17">
          <div class="search-area">
            <el-form :model="queryParams" @submit.native.prevent ref="queryForm" size="small" :inline="true" label-width="68px">
              <el-form-item label="用户账号" prop="userName">
                <el-input
                  v-model="queryParams.userName"
                  placeholder="请输入用户账号"
                  clearable
                  style="width: 240px"
                  @keyup.enter.native="handleQuery"
                />
              </el-form-item>
              <el-form-item label="用户名称" prop="personName">
                <el-input
                  v-model="queryParams.personName"
                  placeholder="请输入用户名称"
                  clearable
                  style="width: 240px"
                  @keyup.enter.native="handleQuery"
                />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
              </el-form-item>
            </el-form>
          </div>

          <el-table ref="multipleTable" height="500" :data="userTableList" border @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column label="用户账号" align="center" prop="userName" />
            <el-table-column label="用户名称" align="center" prop="personName" />
            <el-table-column label="部门" align="center" prop="deptName" />
          </el-table>
          <pagination
            :total="userTotal"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getUserList"
          />
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleTaskUserComplete">确 定</el-button>
        <el-button @click="userOpen = false">取 消</el-button>
      </div>
    </el-dialog>



  </div>
</template>

<script>
import { getWorkOrder, addWorkOrderAssign, transferWorkOrderAssign, workOrderBackApproval, workOrderApproval,updateOrder } from '@/api/biz/workOrder'
import { listUser } from "@/api/system/user";
import { treeselect as deptTreeSelect } from "@/api/system/dept";
import QQMap from "../../../components/QQMap/map";

export default {
  name: "WorkOrderDetail",
  props: {
    workOrderNo: {
      type: String
    },
    detailType: {
      type: String,
      default: 'detail'
    },
    show: {
      type: Boolean
    },
    confirmControls: {
      type:Function
    },
    dialogWidth: {
      type: String,
      default: '1555px'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    },
    modal: {
      type: Boolean,
      default: true
    },
    fromSource: {
      type: String,
      default: ''
    },
    uuid: {
      type: String,
      default: ''
    }
  },
  dicts: ['work_order_level', 'work_order_type', 'work_order_status', 'work_order_process_node'],
  components: {QQMap },
  data() {
    return {
      imagePreviewUrl: this.$imagePreviewUrl,
      workOrderDetail: {},
      workOrderProcessList: [],
      map: null,

      fromAssign:{
        workOrderNo:null,
        description:null,
      },
      fromBackApproval:{
        workOrderNo:null,
        approvalResult:"1",
        description:"",
        fileList:"[]"
      },
      fromApproval:{
        workOrderNo:null,
        approvalResult:"1",
        description:"",
        fileList:"[]"
      },

      assignList:[],
      selectedUser: {
        ids: [],
        names: [],
        text: []
      },
      userOpen: false,
      deptName: undefined,
      deptOptions: [],
      deptProps: {
        children: "children",
        label: "label"
      },
      deptTempOptions: [],
      userTableList: [],
      userTotal: 0,
      selectedUserDate: [],
      // 查询参数
      queryParams: {
        deptId: undefined,
        personName: undefined,
        userName: undefined,
        pageNum:1,
        pageSize:10
      }
    };
  },
  computed: {
    _show: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    isUpdateStatus() {
      return this.detailType == "update";
    }
  },
  created() {
    this.getWorkOrderDetailHandle()
  },
  methods: {
    // 取消按钮
    cancel() {
      this.open = false;
      this.fromAssign={
        workOrderNo:null,
        description:null,
        uuid:this.uuid
      }
      this.fromBackApproval={
        workOrderNo:null,
        approvalResult:"1",
        description:"",
        fileList:"[]",
        uuid:this.uuid
      }
      this.fromApproval={
        workOrderNo:null,
        approvalResult:"1",
        description:"",
        fileList:"[]",
        uuid:this.uuid
      }
      this.assignList = [];
      this.selectedUser = {
        ids: [],
        names: [],
        text: []
      }
      this.resetForm("fromAssign");
      this.resetForm("fromBackApproval");
      this.resetForm("fromApproval");
      this.$emit('update:show', false)
    },
    /** 工单分派 */
    submitFormAssign() {
      if(this.assignList.length <=0){
        this.$modal.msgError("请选择分派单位")
      }
      this.fromAssign.workOrderNo = this.workOrderNo
      addWorkOrderAssign({...this.fromAssign, processPersonList: this.assignList}).then(response => {
        this.$modal.msgSuccess("操作成功")
        if(this.fromSource==""){
          location.reload();
        }else {
          this.$emit('reloadList')
          this.$emit('update:show', false)
        }
      });
    },
    /** 工单转派 */
    submitFormTransfer() {
      if(this.assignList.length <=0){
        this.$modal.msgError("请选择转派单位")
      }
      this.fromAssign.workOrderNo = this.workOrderNo
      transferWorkOrderAssign({...this.fromAssign, processPersonList: this.assignList}).then(response => {
        this.$modal.msgSuccess("操作成功");
        if(this.fromSource==""){
          location.reload();
        }else {
          this.$emit('reloadList')
          this.$emit('update:show', false)
        }
      });
    },
    /** 退回审核 */
    submitFormBackApproval() {
      this.fromBackApproval.workOrderNo = this.workOrderNo
      let uploadFileList = JSON.parse(this.fromBackApproval.fileList)
      let fileList = [];
      if(uploadFileList && uploadFileList.length>0){
        uploadFileList.forEach(item=>{
          fileList.push({
            fileName: item.name,
            fileUrl: item.url
          });
        })
      }
      workOrderBackApproval({...this.fromBackApproval, fileList, processPersonList: this.assignList}).then(response => {
        this.$modal.msgSuccess("操作成功");
        if(this.fromSource==""){
          location.reload();
        }else{
          this.$emit('reloadList')
          this.$emit('update:show', false)
        }
      });
    },
    /** 工单审批 */
    submitFormApproval() {
      this.fromApproval.workOrderNo = this.workOrderNo
      let uploadFileList = JSON.parse(this.fromApproval.fileList)
      let fileList = [];
      debugger
      if(uploadFileList && uploadFileList.length>0){
        uploadFileList.forEach(item=>{
          fileList.push({
            fileName: item.name,
            fileUrl: item.url
          });
        })
      }
      workOrderApproval({...this.fromApproval, fileList}).then(response => {
        this.$modal.msgSuccess("操作成功");
        if(this.fromSource==""){
           location.reload();
        }else{
          this.$emit('reloadList')
          this.$emit('update:show', false)
        }

      });
    },
    getWorkOrderDetailHandle() {
      getWorkOrder(this.workOrderNo).then(res => {
        let workOrderDetail = res.data;
        if(workOrderDetail.fileList && workOrderDetail.fileList.length>0){
          let list = [];
          workOrderDetail.fileList.forEach(item=>{
            list.push({
              name: item.fileName,
              url: item.fileUrl
            });
          })
          workOrderDetail.fileList = list;
        }
        if(workOrderDetail.workOrderProcessList && workOrderDetail.workOrderProcessList.length>0){
          workOrderDetail.workOrderProcessList.forEach(process=>{
            if(process.fileList && process.fileList.length > 0){
              let list = [];
              process.fileList.forEach(item=>{
                list.push({
                  name: item.fileName,
                  url: item.fileUrl
                });
              })
              process.fileList = list;
            }
          })
        }
        console.log(workOrderDetail)
        this.workOrderDetail = workOrderDetail
        this.workOrderProcessList = workOrderDetail.workOrderProcessList
        this.$nextTick(()=>{
          console.log(this.$refs)
          this.$refs.map.initMap(true);
          if(workOrderDetail.longitude && workOrderDetail.latitude){
              let markerOptions = {
                isSingle:true,
                isCenter:true,
                longitude:workOrderDetail.longitude,
                latitude:workOrderDetail.latitude,
              }
              this.$refs.map.addMarker(markerOptions);
              //this.$refs.gismap.addMark(workOrderDetail.longitude, workOrderDetail.latitude,this.map,false,true,"bottom")
          }
        })
      });
    },
    onSelectUsers() {
      this.selectedUserDate = [...this.assignList]
      this.$refs.multipleTable?.clearSelection();
      this.getDeptOptions();
      this.handleNodeClick({id:''})
      this.userOpen = true;
    },
    /**
     * 查询部门下拉树结构
     */
    getDeptOptions() {
      return new Promise((resolve, reject) => {
        if (!this.deptOptions || this.deptOptions.length <= 0) {
          deptTreeSelect().then(response => {
            this.deptTempOptions = response.data;
            this.deptOptions = response.data;
            resolve()
          })
        } else {
          reject()
        }
      });
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.queryParams.pageNum = 1;
      this.queryParams.deptId = data.id;
      this.getUserList();
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getUserList();
    },
    resetQuery() {
      this.queryParams.pageNum = 1;
      this.queryParams.userName = undefined;
      this.queryParams.personName = undefined;
      this.resetForm("queryForm");
      this.getUserList();
    },
    /** 查询用户列表 */
    getUserList() {
      listUser(this.formatParams(this.queryParams, this.dateRange)).then(response => {
        this.userTableList = response.rows;
        this.userTotal = response.total;

        let selectedUserDate = []
        this.userTableList.forEach(row=>{
          let index = this.selectedUserDate.findIndex((user)=>{return user.userId== row.userId});
          if(index>=0){
            selectedUserDate.push(row);
          }
        })
        this.$nextTick(()=>{
          selectedUserDate.forEach(row=>{
            this.$refs.multipleTable.toggleRowSelection(row);
          })
        })
      });
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.userTableList.forEach(row=>{
        let index = this.selectedUserDate.findIndex((user)=>{return user.userId== row.userId});
        if(index>=0){
          this.selectedUserDate.splice(index, 1);
        }
      })
      this.selectedUserDate = this.selectedUserDate.concat(selection);
    },
    handleTaskUserComplete() {
      if (!this.selectedUserDate || this.selectedUserDate.length <= 0) {
        this.$modal.msgError('请选择处理人');
        return;
      }
      this.assignList = []
      for(let i=0; i < this.selectedUserDate.length; i++){
        let user = this.selectedUserDate[i]
        this.assignList.push({
          userId: user.userId,
          processPersonName: user.personName,
          processPersonId: user.personId,
          processDeptName: user.deptName,
          processDeptId: user.deptId,
        })
      }
      this.userOpen = false;
    },
    acceptFile(e) {
      const allowHook = {
        video: ".mp4, .ogv, .ogg, .webm,",
        img: ".jpg, .jpeg, .png, .gif,",
      };
      if (e) {
        return allowHook[e];
      }
      let srt = '';
      for (const k in allowHook) {
        srt += allowHook[k];
      }
      return srt;
    },
    comfirmUpdate() {
      const requiredFields = {
        workOrderName:"任务名称",
        workOrderLevel:"任务级别",
        workOrderType:"任务类型",
        description:"任务描述",
        workOrderTime: "发生时间"
      }
      for (const key in requiredFields) {
        if (!this.workOrderDetail[key]) {
          this.$modal.msgWarning(`${requiredFields[key]}为必填`);
          return
        }
      }

      let workOrderDetail = this.workOrderDetail;
      if(workOrderDetail.fileList && workOrderDetail.fileList.length>0){
        let uploadFileList = JSON.parse(this.workOrderDetail.fileList)
        let fileList = [];
        if(uploadFileList && uploadFileList.length>0){
          uploadFileList.forEach(item=>{
            fileList.push({
              fileName: item.name,
              fileUrl: item.url
            });
          })
        }
        workOrderDetail.fileList = fileList;
      }
      updateOrder(workOrderDetail).then(response => {
        this.$modal.msgSuccess("修改成功");
        this.open = false;
        this.$emit('confirmControls')
        this.getWorkOrderDetailHandle();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.el-date-editor, .el-select{
  width: 100%;
}
.file-show-container{
  ::v-deep .upload-file-list {
    .el-upload-list__item{
      margin-bottom: 0px;
      margin-top: 0px;
      border: none;
    }
    .el-link--inner{
      min-width: 40px;
      .el-icon-document{
        max-width: 180px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

::v-deep .el-textarea__inner {
  resize: none;
 }
</style>
